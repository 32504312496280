import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPageTitle, LazyUTable, LazyFaq, LazyUBanner, LazyUHighlight, LazyUSummaryCard, LazyCompoundCalculator, LazyPensionCalculator, LazyArticleLayout, LazyCategoryLayout, LazyDefaultLayout, LazyHomeLayout, LazyArticleAuthor, LazyCtaWidget, LazyCategoryAlternativeCard, LazyHomeSection, LazyHomeSectionContent, LazyUBreadcrumbs, LazyUBtn, LazyUBtnGroup, LazyUFeatureCard, LazyUInfoCard, LazyUExpansionPanel, LazyUIcon, LazyUIllustration, LazyULink, LazyULogo, LazyUPagination, LazyUSelect, LazyUSlider, LazyUText, LazyUTextField, LazyUContent, LazyProseA, LazyProseBlockquote, LazyProseCode, LazyProseEm, LazyProseH1, LazyProseH2, LazyProseH3, LazyProseH4, LazyProseH5, LazyProseH6, LazyProseHr, LazyProseImg, LazyProseLi, LazyProseOl, LazyProseP, LazyProsePre, LazyProseScript, LazyProseStrong, LazyProseTable, LazyProseTbody, LazyProseTd, LazyProseTh, LazyProseThead, LazyProseTr, LazyProseUl } from '#components'
const lazyGlobalComponents = [
  ["PageTitle", LazyPageTitle],
["UTable", LazyUTable],
["Faq", LazyFaq],
["UBanner", LazyUBanner],
["UHighlight", LazyUHighlight],
["USummaryCard", LazyUSummaryCard],
["CompoundCalculator", LazyCompoundCalculator],
["PensionCalculator", LazyPensionCalculator],
["ArticleLayout", LazyArticleLayout],
["CategoryLayout", LazyCategoryLayout],
["DefaultLayout", LazyDefaultLayout],
["HomeLayout", LazyHomeLayout],
["ArticleAuthor", LazyArticleAuthor],
["CtaWidget", LazyCtaWidget],
["CategoryAlternativeCard", LazyCategoryAlternativeCard],
["HomeSection", LazyHomeSection],
["HomeSectionContent", LazyHomeSectionContent],
["UBreadcrumbs", LazyUBreadcrumbs],
["UBtn", LazyUBtn],
["UBtnGroup", LazyUBtnGroup],
["UFeatureCard", LazyUFeatureCard],
["UInfoCard", LazyUInfoCard],
["UExpansionPanel", LazyUExpansionPanel],
["UIcon", LazyUIcon],
["UIllustration", LazyUIllustration],
["ULink", LazyULink],
["ULogo", LazyULogo],
["UPagination", LazyUPagination],
["USelect", LazyUSelect],
["USlider", LazyUSlider],
["UText", LazyUText],
["UTextField", LazyUTextField],
["UContent", LazyUContent],
["ProseA", LazyProseA],
["ProseBlockquote", LazyProseBlockquote],
["ProseCode", LazyProseCode],
["ProseEm", LazyProseEm],
["ProseH1", LazyProseH1],
["ProseH2", LazyProseH2],
["ProseH3", LazyProseH3],
["ProseH4", LazyProseH4],
["ProseH5", LazyProseH5],
["ProseH6", LazyProseH6],
["ProseHr", LazyProseHr],
["ProseImg", LazyProseImg],
["ProseLi", LazyProseLi],
["ProseOl", LazyProseOl],
["ProseP", LazyProseP],
["ProsePre", LazyProsePre],
["ProseScript", LazyProseScript],
["ProseStrong", LazyProseStrong],
["ProseTable", LazyProseTable],
["ProseTbody", LazyProseTbody],
["ProseTd", LazyProseTd],
["ProseTh", LazyProseTh],
["ProseThead", LazyProseThead],
["ProseTr", LazyProseTr],
["ProseUl", LazyProseUl],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
